import {graphql} from 'gatsby';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Card, CardBody, CardFooter, CardHeader, CardText} from 'reactstrap';
import {Link} from '../../plugins/gatsby-plugin-i18next';
import withI18next from '../../plugins/gatsby-plugin-i18next/withI18next';
import MainLayout from '../layouts/main';
import constants from '../utils/constants';

const styles = require('./index.module.scss');

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string,
      },
    },
  };
}

function Page(props: Props) {

  const {data} = props;
  const {t} = useTranslation();

  return (
    <MainLayout currentPage={constants.PAGE_ABOUT_US}>
      <h1>{t(constants.PAGE_ABOUT_US + '-about')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_ABOUT_US + '-about-desc',
            {title: data.site.siteMetadata.title}),
        }}
      />
      <h1
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_ABOUT_US + '-why-another', {title: data.site.siteMetadata.title}),
        }}
      />
      <p dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-why-another-desc')}}/>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_ABOUT_US + '-why-another-desc-p2',
            {title: data.site.siteMetadata.title}),
        }}
      />
      <h1>{t(constants.PAGE_ABOUT_US + '-current-offerings')}</h1>
      <p>{t(constants.PAGE_ABOUT_US + '-current-offerings-desc')}</p>
      <div className={styles.cards}>
        <Card className={styles.json}>
          <CardHeader><a href="/json/">{t(constants.PAGE_JSON)}</a></CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-current-offerings-json')}}/>
          </CardBody>
          <CardFooter>
            <Link to={'/json/'} role={'button'}><Button>{t(constants.PAGE_ABOUT_US + '-try-it')}</Button></Link>
          </CardFooter>
        </Card>
        <Card className={styles.xml}>
          <CardHeader><a href="/xml/">{t(constants.PAGE_XML)}</a></CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-current-offerings-xml')}}/>
          </CardBody>
          <CardFooter>
            <Link to={'/xml/'} role={'button'}><Button>{t(constants.PAGE_ABOUT_US + '-try-it')}</Button></Link>
          </CardFooter>
        </Card>
        <Card className={styles.sql}>
          <CardHeader><a href="/sql/">{t(constants.PAGE_SQL)}</a></CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-current-offerings-sql')}}/>
          </CardBody>
          <CardFooter>
            <Link to={'/sql/'} role={'button'}><Button>{t(constants.PAGE_ABOUT_US + '-try-it')}</Button></Link>
          </CardFooter>
        </Card>
        <Card className={styles.uuid}>
          <CardHeader><a href="/uuid/">{t(constants.PAGE_UUID)}</a></CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-current-offerings-uuid')}}/>
          </CardBody>
          <CardFooter>
            <Link to={'/uuid/'} role={'button'}><Button>{t(constants.PAGE_ABOUT_US + '-try-it')}</Button></Link>
          </CardFooter>
        </Card>
      </div>
      <h1>{t(constants.PAGE_ABOUT_US + '-disclaimer')}</h1>
      <p>{t(constants.PAGE_ABOUT_US + '-disclaimer-desc')}</p>
      <h1>{t(constants.PAGE_ABOUT_US + '-contact')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_ABOUT_US + '-contact-desc',
            {title: data.site.siteMetadata.title}),
        }}
      />
      <h1>{t(constants.PAGE_ABOUT_US + '-changelog')}</h1>
      <dl
        className={styles.changelog}
        dangerouslySetInnerHTML={{__html: t(constants.PAGE_ABOUT_US + '-changelog-desc')}}
      />
    </MainLayout>
  );
}

export default withI18next()(Page);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
